import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import TreeView from '@mui/lab/TreeView';
import FolderTwoToneIcon from '@mui/icons-material/FolderTwoTone';
import TreeItem from '@mui/lab/TreeItem';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import ActionButtons from './ActionButtons';
import FileDownload  from 'components/FileDownload ';
import { useUser } from 'contexts/user';
import { useParams } from 'react-router-dom';

const useMainStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateRows: 'max-content auto',
    },
    wrapper: {
        display: 'inline-flex',
        gap: '0.5rem',
        alignItems: 'center'
    },
    codeBlock: {

    },
    scrollContainer: {
        padding: theme.spacing(.5, 1),
        borderRadius: theme.shape.borderRadius,
        overflow: 'auto',
        backgroundColor: theme.palette.background.default,
        '& > pre, & .MuiTreeItem-label, & .emptyText': {
            fontFamily: 'Menlo,Consolas,"Droid Sans Mono",monospace',
        },
        '& .MuiTreeItem-content.Mui-disabled': {
            opacity: '1',
        },
        '& .emptyText': {
            fontStyle: 'italic',
        },
        '& .emptyIcon': {
            display: 'inline-block',
            width: "20px"
        }
    }
}));

function FileListView(props) {
    const {
        files, className, expanded, selected,
        defaultCollapseIcon = <FolderTwoToneIcon />, defaultExpandIcon = <FolderTwoToneIcon />
    } = props;

    const expandedIds = !isEmpty(expanded) ? expanded : [];
    let result = [];
    let level = { result };

    files.forEach(path => {
        path.name.split('/').reduce((r, name, i, a) => {
            if (!r[name]) {
                if (expanded) {
                    expandedIds.push(`${name}-0`)
                }
                r[name] = { result: [] };
                r.result.push({ name, children: r[name].result, progress: i + 1 === a.length ? path.progress : undefined })
            }

            return r[name];
        }, level)
    })

    function content(parentChildren) {
        const getIcon = (children, progress) => {
            let Icon = undefined;

            if (!children.length && progress && progress !== 100) {
                Icon = <CircularProgress title={`${progress}%`} size="15px" variant={!progress ? "indeterminate" : "determinate"} value={progress} />
            } else if (!children.length && progress === 100) {
                Icon = <CheckCircleTwoToneIcon label={`${Math.round(progress)}%`} color="success" />
            }
            return Icon
        }

        return parentChildren.map(({ name, children, progress }, index) => (
            <TreeItem
                key={index}
                nodeId={`${name}-${!children.length ? index : 0}`}
                label={name}
                disabled={!children.length}
                icon={(
                    parentChildren.length === 1 && !name ?
                        <span className="emptyIcon" />
                        :
                        getIcon(children, progress)
                )}
            >

                {
                    parentChildren.length === 1 && !name ? (

                        <Typography
                            variant="caption"
                            component="span"
                            color="text.secondary"
                            className="emptyText"
                        >
                            <FormattedMessage component={'span'} id="empty" />
                        </Typography>
                    ) : content(children)
                }
            </TreeItem>
        )
        )
    }

    return (
        <TreeView
            aria-label="file system navigator"
            defaultExpanded={expandedIds}
            selected={selected}
            className={className}
            defaultCollapseIcon={defaultCollapseIcon}
            defaultExpandIcon={defaultExpandIcon}
            sx={{ flexGrow: 1, overflowY: 'auto' }}
        >
            {
                content(result)
            }
        </TreeView>
    )
}


function Content(props) {
    const classes = useMainStyles();
    const { api } = useUser();
    const { job_data, job_type, job_output, job_error, job_id, job_status } = props;
    const [files, setFiles] = React.useState([]);
    const {client } = useParams();
    if (job_type === 'EXTERNAL_COMMAND') {
        const { host, command } = job_data;

        return (
            <>
                <DialogContentText component={'span'} paragraph gutterBottom>
                    <FormattedMessage component={'span'} id="external_command" values={{
                        host: host,
                        command: (
                            <div className={classes.scrollContainer}>
                                <pre>{command}</pre>
                            </div>
                        )
                    }} />
                </DialogContentText>
                {
                    !job_output || isEmpty(job_output) ? null : (
                        <DialogContentText component={'span'} paragraph gutterBottom>
                            <FormattedMessage component={'span'} id="output:" />
                            <div className={classes.scrollContainer}>
                                <pre>
                                    {job_output}
                                </pre>
                            </div>
                        </DialogContentText>
                    )
                }
                {
                    !job_error || isEmpty(job_error) ? null : (
                        <DialogContentText component={'span'} paragraph gutterBottom>
                            <Typography color="error">
                                <FormattedMessage component={'span'} id="error:" />
                            </Typography>
                            <div className={classes.scrollContainer}>
                                <pre>
                                    {job_error}
                                </pre>
                            </div>
                        </DialogContentText>
                    )
                }
                {isEmpty(files) || job_status !== "SUCCESS"  ? null : (
                    <FileDownload files={files}  daysBeforeDelete={30}/>
                    )
                }
            </>
        )
    }

    if (job_type === 'AUDIO_TRANSCRIPTION') {
        const {
            host:_host, audio_file_path, output_dir,
            output_email, model, language, output_format,
            output_filename, max_line_width, max_line_count
        } = job_data;

        async function fetchFiles(abortController = new AbortController()) {
            try {
                const response = await api.get(`/staccato/${client}/${job_id}/files`, {}, { signal: abortController.signal });
    
                if (response.ok) {
                    const data = await response.json();
                    setFiles(data.files);
                } else {
                    setPages([]);
                }
            } catch (error) {
                if (!abortController.aborted) {
                    console.error(error);
                }
            }
        }

        let labels = ['model', 'language', 'format', 'max_line_width', 'max_line_count'];
        let options = [model, language, output_format, max_line_width, max_line_count].map((k, i) => k ? `${labels[i]}: ${k}` : '').filter((v) => !!v).join(' ');
        let host = '';

        if (_host) {
            host = <p>{` `}<FormattedMessage id="on(placement)" /><em>{` ${_host}`}</em></p>
        }

        if (options) {
            options = <p>{` `}<FormattedMessage id="with" /><em>{` ${options}`}</em></p>
        }

        const ext = output_format == 'all' ? '[srt,vtt,txt,tsv,json]' : output_format;
        const shown_filename = output_filename ? `/${output_filename}.${ext}` : "";
        const output_path = output_dir + shown_filename;

        React.useEffect(() => {
            const abortController = new AbortController();
            fetchFiles(abortController);

            return () => {
                abortController.abort();
            }
        }, [job_id]);

        return (
            <>
                {/* <DialogContentText component={'span'} paragraph gutterBottom>
                    <FormattedMessage component={'span'} id="audio_transcription" values={{
                        host: host,
                        file: (
                            <div className={classes.scrollContainer}>
                                <pre>{audio_file_path}</pre>
                            </div>
                        ),
                        output: (
                            <div className={classes.scrollContainer}>
                                <pre>{output_path.replaceAll('"', '')}</pre>
                            </div>
                        ),
                        options: options,
                    }} />
                </DialogContentText> */}
                {
                    !output_email ? null : (
                        <DialogContentText component={'span'} paragraph gutterBottom>
                           <FormattedMessage component={'span'} id="email_will_send_to_x" values={{
                                email: (
                                    <Stack direction="row" spacing={2}>
                                        {
                                            output_email.split(',').map((e) => <Chip key={e} label={e} />)
                                        }
                                    </Stack>
                                )
                            }}/>
                        </DialogContentText>
                    )
                }
                {
                    !job_output || isEmpty(job_output) ? null : (
                        <DialogContentText component={'span'} paragraph gutterBottom>
                            <FormattedMessage component={'span'} id="output:" />
                            <div className={classes.scrollContainer}>
                                <pre>
                                    {job_output}
                                </pre>
                            </div>
                        </DialogContentText>
                    )
                }
                {
                    !job_error || isEmpty(job_error) ? null : (
                        <DialogContentText component={'span'} paragraph gutterBottom>
                            <Typography color="error">
                                <FormattedMessage component={'span'} id="error:" />
                            </Typography>
                            <div className={classes.scrollContainer}>
                                <pre>
                                    {job_error}
                                </pre>
                            </div>
                        </DialogContentText>
                    )
                }
                {isEmpty(files) || job_status !== "SUCCESS" ? null : (
                <FileDownload files={files}  daysBeforeDelete={30}/>
                )
                }
                
            </>
        )
    }

    // if (['COPY', 'MOVE'].includes(job_type)) {
    //     const { from, to } = job_data;
    //     let files = [];
    //     try {
    //         files = JSON.parse(job_output)['files'];
    //     } catch (error) { }

    //     return (
    //         <>
    //             <DialogContentText component={'span'} paragraph gutterBottom>
    //                 <FormattedMessage component={'span'}
    //                     id={job_type === 'COPY' ? "copying_x_to_y" : "moving_x_to_y"}
    //                     values={{
    //                         from: (
    //                             <div className={classes.scrollContainer}>
    //                                 <pre>
    //                                     {from}
    //                                 </pre>
    //                             </div>
    //                         ),
    //                         to: (
    //                             <div className={classes.scrollContainer}>
    //                                 <pre>{to}</pre>
    //                             </div>
    //                         )
    //                     }}
    //                 />
    //             </DialogContentText>

    //             {
    //                 !files || isEmpty(files) ? null : (
    //                     <DialogContentText component={'span'} paragraph gutterBottom>
    //                         <FormattedMessage component={'span'} id="including" />
    //                         <FileListView
    //                             expanded
    //                             className={classes.scrollContainer}
    //                             files={files}
    //                         />
    //                     </DialogContentText>
    //                 )
    //             }
    //         </>
    //     );
    // }

    // if (['DELETE'].includes(job_type)) {
    //     const { path } = job_data;
    //     let files = [];
    //     let root = ""
    //     try {
    //         files = JSON.parse(job_output)['files'];
    //     } catch (error) { }

    //     if (files.length) {
    //         root = path.split('/').slice(0, -1).join('/') + '/';
    //     }

    //     return (
    //         <>
    //             <DialogContentText component={'span'} paragraph gutterBottom>
    //                 <FormattedMessage component={'span'} id="deleting_path" values={{ path: <pre>{path}</pre> }} />
    //             </DialogContentText>
    //             {
    //                 !files || isEmpty(files) ? null : (
    //                     <DialogContentText component={'span'} paragraph gutterBottom>
    //                         <FormattedMessage component={'span'} id="including" />
    //                         <FileListView
    //                             expanded
    //                             className={classes.scrollContainer}
    //                             files={files.map((name) => ({ name: name.replace(root, ''), progress: undefined }))}
    //                         />
    //                     </DialogContentText>
    //                 )
    //             }
    //         </>
    //     );
    // }

    return (<></>)
}


function JobDetails(props) {
    const classes = useMainStyles();
    const [open, setOpen] = React.useState(false);

    const {
        job_name, job_id, job_data, job_type, job_output, job_error,
        pages,
        setPages,
        showHidden,
        refreshPages,
        status
    } = props;
    const handleClickOpen = () => {
        setOpen(true);
    };

    function handleClose() {
        setOpen(false);
    };

    return (
        <>
            <IconButton color="secondary" onClick={handleClickOpen}>
                <InfoTwoToneIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose} maxWidth="lg">
                <DialogTitle>
                    <FormattedMessage component={'span'} id="job_detail" values={{ name: job_name, job_id }} />
                </DialogTitle>
                <DialogContent>
                    <Content
                        job_id={job_id}
                        job_data={job_data}
                        job_type={job_type}
                        job_output={job_output}
                        job_error={job_error}
                        job_status={status}
                    />
                </DialogContent>
                <DialogActions>
                    <ActionButtons
                        sx={{ marginRight: 'auto' }}
                        spacing={1}

                        pages={pages}
                        setPages={setPages}
                        selected={[job_id]}
                        showHidden={showHidden}
                        refreshPages={refreshPages}
                        onSuccess={handleClose}
                    />
                    <Button
                        onClick={handleClose}>
                        <FormattedMessage id="close" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default JobDetails;

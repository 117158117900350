import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

function FormSelect(props) {
    const {
        children, fullWidth, label,
        onChange, multiple, name,
        value, margin, size, variant,
        disabled, required, placeholder,
        displayEmpty, clearable,
    } = props;

    let renderValue = undefined;
    let hasValue = (placeholder || displayEmpty) && !value

    if (placeholder && [null, undefined, ''].includes(value)) {
        renderValue = (selected) => {
            if ([null, undefined, ''].includes(selected) || selected.length === 0) {
                return <em>{placeholder}</em>;
            }

            return multiple ? selected.join(', ') : selected;
        }
    }

    return (
        <FormControl required={required} margin={margin} size={size} fullWidth={fullWidth} variant={variant} disabled={disabled}>
            {
                !label ? null : (
                    <InputLabel shrink={hasValue ? true : undefined}>
                        {label}
                    </InputLabel>
                )
            }
            <Select
                notched={hasValue ? true : undefined}
                displayEmpty={displayEmpty}
                onChange={onChange}
                name={name}
                value={value}
                multiple={multiple}
                label={label}
                margin={margin}
                size={size}
                renderValue={renderValue}
            >
                {
                    !clearable ? null : (
                        <MenuItem value="">
                            <em>
                                <FormattedMessage id="NONE" />
                            </em>
                        </MenuItem>
                    )
                }
                {children}
            </Select>
        </FormControl>
    );
}

FormSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    children: PropTypes.node.isRequired,
    label: PropTypes.node,
    name: PropTypes.string,
    multiple: PropTypes.bool,
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    margin: PropTypes.oneOf(['dense', 'none', 'normal']),
    size: PropTypes.oneOf(['small', 'medium']),
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

FormSelect.defaultProps = {
    label: null,
    name: undefined,
    multiple: false,
    fullWidth: false,
    disabled: false,
    required: false,
    margin: 'none',
    size: undefined,
    required: false,
    variant: 'outlined',
};

export default FormSelect;

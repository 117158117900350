import React from 'react';
import throttle from 'lodash/throttle';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, FormattedDate, FormattedTime, FormattedDateParts } from 'react-intl';

import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import useLocationSearch from 'hooks/useLocationSearch';
// import { useLocationSearch } from 'contexts/locationSearch';
import JobDetails from 'components/staccato/Jobs/Details';
import JobStatus from 'components/staccato/Jobs/Status';
import ActionButtons from 'components/staccato/Jobs/ActionButtons';
import DialogActions from '@mui/material/DialogActions';

import Table from 'components/Table/EnhancedPagedTable';
import StandardDataDate from 'components/StandardDataDate';
import FormattedDuration from 'components/FormattedDuration';
import { useUser } from 'contexts/user';
import { useSocketListener } from 'contexts/socket';

import { useParams } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
    },
    warning: {
        color: theme.palette.warning.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    success: {
        color: theme.palette.success.main,
    },
    pending: {
        color: theme.palette.info.main,
    },
}));


const jobStatuses = [
  {
    "description": "En préparation",
    "display_order": 0,
    "is_active": true,
    "job_status_id": "PREPARING"
  },
  {
    "description": "En traitement",
    "display_order": 10,
    "is_active": true,
    "job_status_id": "PROCESSING"
  },
  {
    "description": "En attente",
    "display_order": 20,
    "is_active": true,
    "job_status_id": "PENDING"
  },
  {
    "description": "Échoué",
    "display_order": 30,
    "is_active": true,
    "job_status_id": "FAILED"
  },
  {
    "description": "Cancellé",
    "display_order": 40,
    "is_active": true,
    "job_status_id": "CANCELLED"
  },
  {
    "description": "Completé",
    "display_order": 90,
    "is_active": true,
    "job_status_id": "SUCCESS"
  }
]


function List(props) {
    const {
        callbackStatuses = [], jobTypes = [], filter, showHidden,
        pages, setPages, selected, setSelected, userJobTypes
    } = props;
    const { setListener, api } = useUser();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isFailed, setFailed] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(false);
    const [page, setPage] = useLocationSearch('page', 0);
    const [rowsPerPage, setRowsPerPage] = useLocationSearch('rowsPerPage', 50);
    const {client } = useParams();

    const jobs = (Object.values(pages) || []).reduce((acc, lst) => ([...acc, ...lst]), []);

    const fullCount = (jobs.length > 0 ? jobs[0].full_count : 0);

    const curPageRows = React.useMemo(() => (pages[page] || []), [pages[page]]);

    const classes = useStyles();

    const sortJobs = React.useCallback((a,b) => {
        if (!jobStatuses.length) {
            return 0;
        }

        const statusOrder = (v) => {
            return (jobStatuses.find(({ job_status_id }) => job_status_id === v.status) || { display_order: 0 }).display_order
        }


        // job_status.display_order ASC
        if (statusOrder(a) > statusOrder(b)) {
            return 1;
        }
        if (statusOrder(a) < statusOrder(b)) {
            return -1;
        }

        // job.end_date DESC NULLS LAST
        if ((a.end_date !== b.end_date && a.end_date === null) || (new Date(a.end_date || 0) > new Date(b.end_date || 0))) {
            return -1;
        }
        if (new Date(a.end_date || 0) < new Date(b.end_date || 0)) {
            return 1;
        }

        // job.start_date DESC NULLS LAST
        if ((a.start_date !== b.start_date && a.start_date === null) || (new Date(a.start_date || 0) > new Date(b.start_date || 0))) {
            return -1;
        }
        if (new Date(a.start_date || 0) < new Date(b.start_date || 0)) {
            return 1;
        }

        // job.created_date ASC
        if (new Date(a.created_date || 0) > new Date(b.created_date || 0)) {
            return 1;
        }
        if (new Date(a.created_date || 0) < new Date(b.created_date || 0)) {
            return -1;
        }

        // job.job_id DESC
        if (a.job_id > b.job_id) {
            return -1;
        }
        if (a.job_id < b.job_id) {
            return 1;
        }

        return 0;

    }, [jobStatuses]);

    async function fetchJobs(flushObjects = false, pageToFetch = 0, limit = 50, abortController = new AbortController()) {
        setIsFetching(true);
        if (page !== pageToFetch) {
            setIsLoading(true);
        }

        try {
            const queryBody = {
                filter,
                job_id: [],
                status: [],
                created_date: [],
                created_by: [],
                job_type: userJobTypes,
                worker_id: [],
                show_hidden: showHidden,
                offset: pageToFetch * limit,
                limit,
            };

            const response = await api.get( `/staccato/job/${client}`, queryBody, { signal: abortController.signal });

            if (response.ok) {
                const data = await response.json();
                setPages((currentPages) => (flushObjects ? { [pageToFetch]: data } : { ...currentPages, [pageToFetch]: data }));
            } else {
                setPages((currentPages) => (flushObjects ? {} : omit(currentPages, pageToFetch)));
                setFailed(true);
            }
            setSelected([]);
        } catch (error) {
            if (!abortController.aborted) {
                console.error(error);
            }
        }
        setIsLoading(false);
        setIsFetching(false);
    }

    const throttledFetchJobs = throttle(async (flushObjects = false, pageToFetch = 0, limit = 50, abortController = new AbortController(), jobData = {}) => {
        if (jobData) {
            let found = false;
            const newJobData = [
                jobData.status,
                jobData.progress,
                jobData.status,
                jobData.start_date,
                jobData.end_date,
            ];

            setPages((currentPages) => ({
                ...currentPages,
                [page]: (currentPages[page] || []).map((cj) => {
                    if (cj.job_id === jobData.id) {
                        found = true;

                        return {
                            ...cj,
                            status: jobData.status,
                            progress: jobData.progress,
                            end_callback_status: jobData.end_callback_status,
                            start_date: jobData.start_date,
                            end_date: jobData.end_date,
                        }
                    }
                    return cj
                }).sort(sortJobs)
            }));
            if (found) {
                return;
            }
        }
        fetchJobs(flushObjects, pageToFetch, limit, abortController)
    }, 1500);

    const handleMessage = React.useCallback((event) => {
        const abortController = new AbortController();
        if (event) {
            const jobData = event ? JSON.parse(event.data) : {};
            const throttled = throttledFetchJobs(true, page, rowsPerPage, abortController, jobData);
        }
        return () => {
            abortController.abort();
        }

    }, [page, rowsPerPage, filter, showHidden, userJobTypes])

    function refreshPages() {
        const abortController = new AbortController();
        const throttled = throttledFetchJobs(true, page, rowsPerPage, abortController, {});
        return () => {
            abortController.abort();
        }
    }

    const ws = useSocketListener('job_update', handleMessage);

    const testClick = React.useCallback(() => {
        const message = JSON.stringify({ channel: 'test' });
        ws.sendMessage(message)
    }, [ws])


    const handlePageChange = React.useCallback((newPage) => {
        if (isEmpty(pages[newPage])) {
            setIsLoading(true);
        }
        setPage(newPage);
    }, [pages]);

    const isRowHidden = ({ is_hidden }) => is_hidden;

    const fetchPageDependencies = React.useMemo(() => [filter, showHidden, userJobTypes], [userJobTypes, filter, showHidden]);

    const tableColumns = [
        {
            field: 'job_name', title: 'name', Component: ({ job_name, job_id, JobStatus }) => (
                <Tooltip
                    PopperProps={{
                        popperOptions: {
                            placement: 'bottom',
                            modifiers: {
                                inner: {
                                    enabled: true,
                                },
                                flip: {
                                    enabled: false,
                                },
                                offset: {
                                    enabled: true,
                                    offset: '0px, 50%r - 50%p',
                                },
                            },
                        },
                    }}
                    title={job_id}
                >
                    <span style={{ overflowWrap: 'anywhere' }} >
                        {job_name}
                    </span>
                </Tooltip>
            )
        },

        {
            field: 'created_by', title: 'CREATED_BY'        },
        {
            field: 'created_date',
            title: 'created',
            align: 'right',
            Component: ({ created_date }) => (
                <span>
                    <StandardDataDate value={new Date(created_date)} />
                </span>
            )
        },
        {
            field: 'start_date',
            title: 'start',
            align: 'right',
            Component: ({ start_date, end_date }) => (
                <span>
                    {
                        !start_date ? null : (
                            <>
                                <StandardDataDate value={new Date(start_date)} />
                            </>
                        )
                    }
                </span>
            )
        },
        {
            field: 'end_date',
            title: 'end',
            align: 'right',
            Component: ({ start_date, end_date }) => (
                <>
                    {
                        !end_date || !start_date ? null : (
                            <Tooltip
                                PopperProps={{
                                    popperOptions: {
                                        placement: 'bottom',
                                        modifiers: {
                                            inner: {
                                                enabled: true,
                                            },
                                            flip: {
                                                enabled: false,
                                            },
                                            offset: {
                                                enabled: true,
                                                offset: '0px, 50%r - 50%p',
                                            },
                                        },
                                    },
                                }}
                                title={<FormattedDuration value={new Date(end_date) - new Date(start_date)} />}
                            >
                                <span>
                                    <StandardDataDate value={new Date(end_date)} />
                                </span>
                            </Tooltip>
                        )
                    }
                    {
                        !end_date || start_date ? null : (
                            <span>
                                <StandardDataDate value={new Date(end_date)} />
                            </span>
                        )
                    }
                </>
            )
        },
        {
            field: 'progress',
            align: 'right',
            Component: (props) => (
                <Stack
                    direction="row"
                    justifyContent="right"
                    alignItems="center"
                    spacing={2}
                >
                    <JobStatus {...props} jobStatuses={jobStatuses} callbackStatuses={callbackStatuses} />
                    <JobDetails {...props}
                        pages={pages}
                        setPages={setPages}
                        jobStatus={JobStatus}
                        showHidden={showHidden}
                        refreshPages={refreshPages}
                    />
                </Stack>
            )
        },
    ];

    return (
        <Paper className={classes.root}>
            <Table
                cellStyle={{ fontFamily: '"Roboto Mono", monospace' }}
                size="small"
                showDivisions={false}
                columns={tableColumns}
                uniqueField="job_id"

                fetchPageDependencies={fetchPageDependencies}
                fetchPage={fetchJobs}
                setPage={handlePageChange}
                page={page}
                pageRows={curPageRows}
                totalRowCount={fullCount}
                isLoading={isLoading}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                hiddenRow={isRowHidden}

                multiple={true}
                isSelectable={true}
                setSelected={setSelected}
                selected={selected}
                additionalActions={isEmpty(selected) ? null :
                    <ActionButtons
                        spacing={2}
                        buttonProps={{
                            variant: "outlined",
                            size: "small"
                        }}

                        pages={pages}
                        setPages={setPages}
                        selected={selected}
                        showHidden={showHidden}
                        refreshPages={refreshPages}
                    ></ActionButtons>
                }
            />
        </Paper>
    )
}

export default List;
